
$(document).ready(function () {

	function checkChartStart () {
		var docViewTop = $(window).scrollTop()
		var docViewBottom = docViewTop + $(window).height() + 100

		var elemTop = $('.chart').offset().top
		var elemBottom = elemTop + $('.chart').height()

		if ((elemBottom <= docViewBottom) && (elemTop >= docViewTop)) {
			$('.chart').addClass('chart--startAnimation')
		}
	}

	window.onload = function () {
		if ($('.chart').length > 0) {
			checkChartStart()
		}
	}

	$(document).on('resize scroll', function () {
		if ($('.chart').length > 0) {
			checkChartStart()
		}
	})

});
